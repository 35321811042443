<template>
  <div>

    <!-- #page-header -->
    <div id="page-header" class="cover" data-image-src="assets/img/bg/kontakt.jpg">
      <div class="container">

        <div class="-wrapper">
          <div class="_inner">

            <h1 v-translate>Kontakt</h1>

          </div>
        </div>

      </div>
    </div>
    <!-- /#page-header -->

    <!-- .section -->
    <section class="section">
      <div class="container">

        <!-- ._header -->
        <header class="_header text-center">
          <h2 v-translate>Máte otázky? Radi vám odpovieme.</h2>
          <p v-translate>Nenašli ste všetky potrebné informácie na tejto stránke? Napíšte nám a my sa Vám radi ozveme späť.</p>
        </header>
        <!-- /._header -->

        <!-- ._body -->
        <div class="_body">
          <div class="row">
            <div class="col-md-4">

              <!-- .contact-box -->
              <div class="contact-box">

                <h3 v-translate>Všeobecné informácie:</h3>

                <a class="tel" href="tel:0258101062" target="_blank"><i class="icon icofont-headphone-alt-2"></i> 02 - 581 010 62</a>
                <a class="mail" href="mailto:info@webpartner.sk" target="_blank"><i class="icon icofont-envelope-open"></i> info@webpartner.sk</a>

              </div>
              <!-- /.contact-box -->

              <!-- .contact-box -->
              <div class="contact-box">

                <h3 v-translate>Zákaznícka podpora</h3>

                <a class="tel" href="tel:0258101062" target="_blank"><i class="icon icofont-headphone-alt-2"></i> 02 581 010 62</a>
                <a class="mail" href="mailto:helpdesk@wy.sk" target="_blank"><i class="icon icofont-envelope-open"></i> helpdesk@wy.sk</a>

              </div>
              <!-- /.contact-box -->

            </div>
            <div class="col-md-6 col-md-offset-1">

              <!-- .form -->
              <form class="form"  @submit.prevent="submitForm">
                <div class="row">
                  <div class="col-md-6">

                    <label v-translate>Vaše meno</label>
                    <input type="text" name="name" v-model="data.name" v-validate="{required: true}">
                    <span class="reg_error">{{ errors.first('name') }}</span>

                  </div>
                  <div class="col-md-6">

                    <label v-translate>Váš e-mail</label>
                    <input type="email" name="email" v-model="data.email" v-validate="{required: true, email: true}">
                    <span class="reg_error">{{ errors.first('email') }}</span>

                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">

                    <label v-translate>Predmet správy:</label>
                    <input type="text" name="subject" v-model="data.subject" v-validate="{required: true}">
                    <span class="reg_error">{{ errors.first('subject') }}</span>

                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">

                    <label v-translate>Správa:</label>
                    <textarea name="text" v-model="data.text" v-validate="{required: true}"></textarea>
                    <span class="reg_error">{{ errors.first('text') }}</span>

                  </div>
                </div>

                <input type="submit" class="alt smaller" v-bind:value="$gettext('odoslať formulár')">
                <span class="reg_error" v-translate v-if="data.error_form_sending">Formulár sa nepodarilo odoslať</span>
                <span class="reg_error" v-translate v-if="data.error_form_validating">Formulár sa nepodarilo validovať</span>

              </form>
              <!-- /.form -->

            </div>
          </div>
        </div>
        <!-- /._body -->

      </div>
    </section>
    <!-- /.section -->

    <section class="section -grey">
      <div class="container">

        <!-- .partners -->
        <div class="partners noOffset">

          <h3 v-translate>Na technologickiej infraštruktúre od WY budú vaši klienti v skvelých rukách. Našim službám a produktom dôverujú aj:</h3>

          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide">

                <!-- .partner -->
                <div class="partner">
                  <div class="_wrapper">

                    <img src="assets/img/logos/ambitas.png" alt="">

                  </div>
                </div>
                <!-- /.partner -->

              </div>
              <div class="swiper-slide">

                <!-- .partner -->
                <div class="partner">
                  <div class="_wrapper">

                    <img src="assets/img/logos/pravdask.png" alt="">

                  </div>
                </div>
                <!-- /.partner -->

              </div>
              <div class="swiper-slide">

                <!-- .partner -->
                <div class="partner">
                  <div class="_wrapper">

                    <img src="assets/img/logos/radioexpres.png" alt="">

                  </div>
                </div>
                <!-- /.partner -->

              </div>
              <div class="swiper-slide">

                <!-- .partner -->
                <div class="partner">
                  <div class="_wrapper">

                    <img src="assets/img/logos/techbox.png" alt="">

                  </div>
                </div>
                <!-- /.partner -->

              </div>
              <div class="swiper-slide">

                <!-- .partner -->
                <div class="partner">
                  <div class="_wrapper">

                    <img src="assets/img/logos/galton.svg" alt="">

                  </div>
                </div>
                <!-- /.partner -->

              </div>
            </div>
          </div>

        </div>
        <!-- /.partners -->

      </div>
    </section>

  </div>
</template>

<script>
    export default {
        name: 'contact',
        metaInfo() {
            return {
                title: this.$gettext('contact_title'),
                titleTemplate: '%s | Webpartner',
                htmlAttrs: {
                    lang: 'sk-SK',
                    amp: undefined
                },
                link: [
                    {rel: 'canonical', href: 'https://webpartner.sk/kontakt'}
                ]
            }
        },
        data() {
            return {
                data: {
                    error_form_sending: false,
                    error_form_validating: false
                },
            }
        },
        mounted: function() {
            const dictionary = {
                en: {
                    messages:{
                        required: this.$gettext('validator_required'),
                        email: this.$gettext('validator_email'),
                    },
                },
            };
            this.$validator.localize(dictionary)
        },
        methods: {
            submitForm: function () {
                const axios = require('axios');
                this.$validator.validateAll().then((result) => {
                    if(result) {
                        axios.post("https://api.controlpanel.sk/wp-send-request", this.data).then(response => {
                            if(response.data.success == true) {
                                this.data.error_form_sending = false;
                                this.$router.push({name: 'message-sent'});
                            } else {
                                this.data.error_form_sending = true;
                            }
                        }).catch(() => {
                            this.data.error_form_sending = true;
                        });
                    }
                }).catch(() => {
                    this.data.error_form_validating = true;
                });
            }
        }
    }
</script>